import React from 'react';
import UserContext from './UserContext';

/**
 * HOC to get user info in to a component.
 * Use React useContext hook if accessing this value in a functional component.
 * @param {React.Component} Component
 */
export default function withUser(Component) {
  return function UserComponent(props) {
    return (
      <UserContext.Consumer>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        {(contexts) => <Component {...props} {...contexts} />}
      </UserContext.Consumer>
    );
  };
}
