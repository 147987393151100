import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { Builder } from '@builder.io/react/lite';
import { withChildren } from "@builder.io/react";
import { addIdAttribute } from "@glowforge/builder-components/lib/util/shared-configs";

import TermsOfServiceModal from "../terms-of-service/terms-of-service-modal";
import { fetchState } from '../../context/UserContext';
import useUser from '../../context/UserContext/useUser';

function AuthWrapper({
  acceptButtonLabel,
  acceptMessage,
  alert,
  children,
  dataTestId, 
  forceLoggedInState,
  legalHeader,
  requiresTerms }) {
  const { user, userFetchState } = useUser();
  const [viewKey, setViewKey] = useState('unknown');
  const [showTerms, setShowTerms] = useState(false);

  useEffect(() => {
    if (userFetchState === fetchState.COMPLETE &&
      Object.entries(user).length > 0) {
      if (requiresTerms && user.show_terms_of_service) {
        setShowTerms(true);
      }
      setViewKey('logged-in');
    } else if (userFetchState !== fetchState.PENDING) {
      setViewKey('logged-out');
    }
  }, [user, userFetchState, requiresTerms]);

  useEffect(() => {
    if (Builder.isEditing) {
      setViewKey(forceLoggedInState ? 'logged-in' : 'logged-out');
    }
  }, [forceLoggedInState]);

  if (userFetchState === fetchState.PENDING) {
    return null;
  }

  const currentView = children?.filter(child => child.key === viewKey);

  const messaging = {
    acceptButtonLabel,
    acceptMessage,
    alert,
    legalHeader
  };

  return (
    <div {...addIdAttribute(dataTestId)}>
      {currentView}
      <TermsOfServiceModal
        messaging={messaging}
        showTerms={showTerms}
        setShowTerms={setShowTerms}
        parentSelector={dataTestId} 
      />
    </div>
  );
}

AuthWrapper.propTypes = {
  acceptButtonLabel: PropTypes.string,
  acceptMessage: PropTypes.string,
  alert: PropTypes.string,
  children: PropTypes.node,
  dataTestId: PropTypes.string,
  forceLoggedInState: PropTypes.bool,
  legalHeader: PropTypes.string,
  requiresTerms: PropTypes.bool,
  termsLink: PropTypes.string,
};

AuthWrapper.defaultProps = {
  acceptButtonLabel: '',
  acceptMessage: '',
  alert: '',
  children: [],
  dataTestId: null,
  forceLoggedInState: false,
  legalHeader: '',
  requiresTerms: false,
  termsLink: null,
}

export default withChildren(AuthWrapper);
