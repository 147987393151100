import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import Markdown from '@glowforge/gf-styled/src/components/markdown';

import {
  LegalHeader,
  LegalContainer,
  LegalFooter,
  AlertText,
  AcceptCheckboxLabel,
  AcceptButton,
} from './terms-of-service.styles';

export default function TermsOfService({ terms, messaging, acceptTerms }) {
  const [accepted, setAccepted] = useState(false);
  const [atBottom, setAtBottom] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const scrollRef = useRef(null);

  const handleScroll = (e) => {
    const scrollArea = e.target;
    if (scrollArea.scrollHeight <= scrollArea.scrollTop + scrollArea.offsetHeight + 180) {
      setAtBottom(true);
      setShowAlert(false);
    }
  };

  useEffect(() => {
    const scroll = scrollRef.current;
    if (scroll) {
      scroll.addEventListener('scroll', handleScroll);
      return () => scroll.removeEventListener('scroll', handleScroll);
    }
    return null;
  }, []);

  const onAcceptCheck = () => {
    setAccepted(!accepted);
    setShowAlert(false);
  };

  const onAcceptTermsOfService = () => {
    if (accepted && atBottom) {
      acceptTerms();
    } else if (!atBottom) {
      setShowAlert(true);
    }
  };

  const disabled = !accepted || !atBottom;

  return (
    <div className='TermsOfServiceModal'>
      <LegalHeader>
        {messaging.legalHeader}
      </LegalHeader>
      <LegalContainer className='termsOfService' ref={scrollRef} >
        <h2>{terms.title}</h2>
        <Markdown source={terms.legalContent} />
      </LegalContainer>
      {showAlert ?
        <AlertText className='alert'>{messaging.alert}</AlertText> :
        null}
      <LegalFooter className='terms-of-service-footer'>
        <AcceptCheckboxLabel className='accept-text' htmlFor='accept-tos-checkbox'>
          <input type='checkbox' id='accept-tos-checkbox' onChange={onAcceptCheck} />
          {messaging.acceptMessage}
        </AcceptCheckboxLabel>
        <AcceptButton
          type='button'
          isDisabled={disabled}
          onClick={onAcceptTermsOfService}>
          {messaging.acceptButtonLabel}
        </AcceptButton>
      </LegalFooter>
    </div>
  );
};

TermsOfService.propTypes = {
  terms: PropTypes.shape({
    title: PropTypes.string,
    legalContent: PropTypes.string,
  }),
  messaging: PropTypes.shape({
    acceptButtonLabel: PropTypes.string,
    acceptMessage: PropTypes.string,
    alert: PropTypes.string,
    legalHeader: PropTypes.string,
  }),
  acceptTerms: PropTypes.func.isRequired,
};

TermsOfService.defaultProps = {
  messaging: {
    acceptButtonLabel: 'I AGREE',
    // eslint-disable-next-line max-len
    acceptMessage: 'By checking the box and clicking "I agree", you are agreeing to the above terms of service and have read, understand and agree to adhere to the user manual operating instructions',
    alert: 'You must read the entire Terms of Service document before continuing.',
    legalHeader: 'Review Terms of Service'
  },
  terms: {
    title: '',
    legalContent: '',
  }
};
