/**
 * @type {import('@builder.io/sdk/dist/src/builder.class').Component}
 */
import { idConfig } from '@glowforge/builder-components/lib/util/shared-configs';

export default {
  name: 'AuthWrapper',
  friendlyName: 'Auth Wrapper',
  // eslint-disable-next-line max-len
  image: 'https://cdn.builder.io/api/v1/image/assets%2F69fac0673d2644f0a0a962e1cb3045ef%2Fd2a3493bdd2c4701bd15df06f3b5dd61',
  // eslint-disable-next-line max-len
  description: "Allows you to set a component with two different states based on the user's login status.",
  noWrap: true,
  inputs: [
    idConfig,
    {
      name: 'requiresTerms',
      type: 'boolean',
      // eslint-disable-next-line max-len
      helperText: 'To view component requires a user to be signed in and up to date on our Terms of Use',
    },
    {
      name: 'legalHeader',
      type: 'text',
      helperText: 'Header for the Legal modal',
      showIf: (options) => options.get('requiresTerms'),
    },
    {
      name: 'alert',
      type: 'text',
      helperText: 'Alert to show if user has not read all the terms',
      showIf: (options) => options.get('requiresTerms'),
    },
    {
      name: 'acceptMessage',
      type: 'text',
      helperText: 'Their agreement to our terms after reading through them',
      showIf: (options) => options.get('requiresTerms'),
    },
    {
      name: 'acceptButtonLabel',
      type: 'text',
      showIf: (options) => options.get('requiresTerms'),
    },
    {
      name: 'forceLoggedInState',
      type: 'boolean',
      helperText: 'Overrides in builder mode to show the authenticated view',
    },
  ],
  defaultChildren: [
    {
      '@type': '@builder.io/sdk:Element',
      layerName: 'Logged In',
      id: 'logged-in',
      component: {
        name: 'Glowforge:Section',
      },
      children: [
        {
          '@type': '@builder.io/sdk:Element',
          component: {
            name: 'Glowforge:Text',
            options: {
              variant: 'title',
              text: 'Logged In',
            },
          },
        },
      ],
    },
    {
      '@type': '@builder.io/sdk:Element',
      layerName: 'Logged Out',
      id: 'logged-out',
      component: {
        name: 'Glowforge:Section',
      },
      children: [
        {
          '@type': '@builder.io/sdk:Element',
          component: {
            name: 'Glowforge:Text',
            options: {
              variant: 'title',
              text: 'Logged Out',
            },
          },
        },
      ],
    },
  ],
};
