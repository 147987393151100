import styled from 'styled-components';
import Modal from 'react-modal';
import Button from '@glowforge/gf-styled/src/components/button/button';

export const LegalModal = styled(Modal)`
  margin: 0 5%;
  position: relative;
  top: 10vh;
  background: #fbfbfb;
`;

export const LegalHeader = styled('div')`
  background-color: #1E93A5;
  color: #fbfbfb;
  padding: 1rem;
  text-transform: uppercase;
`;

export const LegalContainer = styled('div')`
  max-height: 60vh;
  overflow-y: auto;
  border: 1px solid #ccc;
  padding: 10px;
`;

export const LegalFooter = styled('div')`
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  padding: 1rem;
`;

export const AcceptCheckboxLabel = styled('label')`
  font-size: 1.1rem;
  line-height: 1.2;
  flex-grow: 1;
  font-weight: normal;
  color: #777;
`;

export const AcceptButton = styled(Button)`
  ${(props) => {
    if (props.isDisabled) {
      return `
        background: #777;
        border-color: #777;

        &:hover {
          background: #777;
          border-color: #777;
          box-shadow: none;
        }
      `;
    }
    return ``;
  }}
`;

export const AlertText = styled('div')`
  padding: 1rem;
  width: 100%;
  background: #D89D92;
  border: 1px solid #911A1F;
  color: #911A1F;
  text-align: center;
  font-weight: 800;
`;