
import AuthWrapper from '../../components/auth-wrapper';
import CreateAccount from '../../components/create-account';

// Any Dotcom components that are to be used on any page
// should be added here so we can easily update
// builder init for live and the 404 page for testing
export default [
  AuthWrapper,
  CreateAccount
];
