import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import TermsOfService from './terms-of-service';
import { LegalModal } from './terms-of-service.styles';
import MagicSvgAnimation from '../magic-svg/magic-svg-animation';
import { getLegalPage } from '../../api/contentful';
import useUser from '../../context/UserContext/useUser';
import { fetchState } from '../../context/UserContext';
import { acceptTermsOfService } from '../../api/core';
import { STATUS } from '../../context/MagicSvgContext/magicSvgReducer';

export default function TermsOfServiceModal({
  messaging,
  showTerms,
  setShowTerms,
}) {
  const [error, setError] = useState(null);
  const [terms, setTerms] = useState(null);
  const [minorVersion, setMinorVersion] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const { user, userFetchState } = useUser();

  useEffect(() => {
    LegalModal.setAppElement('body');
  }, []);

  useEffect(() => {
    async function fetchTerms() {
      if (user.show_terms_of_service) {
        const termsData = await getLegalPage('termsOfService');
        setTerms(termsData ? termsData.fields : null);
        setMinorVersion(termsData ? termsData.sys.revision : null);
        setIsLoading(false);
        setError(null);
      }
    }
    fetchTerms();
  }, [user]);

  useEffect(() => {
    switch (userFetchState) {
      case fetchState.COMPLETE:
        setError(null);
        setIsLoading(false);
        break;
      case fetchState.PENDING:
        setIsLoading(true);
        setError(null);
        break;
      case fetchState.ERROR:
        setError(user?.errors || 'Could not load Terms');
        break;
      default:
        break;
    }
  }, [user, userFetchState]);

  const acceptTerms = async () => {
    setIsLoading(true);
    setError(null);

    try {
      await acceptTermsOfService(minorVersion);
      setShowTerms(false);
    } catch (e) {
      setError(e.message);
      setIsLoading(false);
    }
  };

  return (
    <LegalModal isOpen={showTerms} className='terms-of-service' style={{
      overlay: {
        backgroundColor: '#00000033',
      }
    }}>
      {isLoading ? <MagicSvgAnimation animationKey={STATUS.LOADING} /> : null}
      {error ? <div>{error}</div> : null}
      {terms && !isLoading ? (
        <TermsOfService
          terms={terms}
          messaging={messaging}
          acceptTerms={acceptTerms}
        />
      ) : null}
    </LegalModal>
  );
}

TermsOfServiceModal.propTypes = {
  messaging: PropTypes.objectOf(
    PropTypes.string
  ).isRequired,
  showTerms: PropTypes.bool,
  setShowTerms: PropTypes.func,
};

TermsOfServiceModal.defaultProps = {
  showTerms: false,
  setShowTerms: () => { },
};
