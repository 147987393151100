export const PAGES = {
  LANDING: 'landing',
  GENERATE: 'generate',
  RESULT: 'result',
};

export const STATUS = {
  LOADING: 'loading',
  SUCCESS: 'success',
  FAILED: 'failed'
};

export const magicSvgActions = {
  SET_USER_TOKEN: 'SET_USER_TOKEN',
  SET_IMAGE: 'SET_IMAGE',
  SET_RECENT_IMAGES: 'SET_RECENT_IMAGES',
  SET_GENERATION_STATUS: 'SET_GENERATION_STATUS',
  SET_SHOW_BANNER: 'SET_SHOW_BANNER',
  SET_BUILDER_PAGE_ID: 'SET_BUILDER_PAGE_ID',
  REGISTER_INSUFFICIENT_TOKEN_ATTEMPT: 'REGISTER_INSUFFICIENT_TOKEN_ATTEMPT',
};

export function magicSvgReducer(state, action) {
  switch (action.type) {
    case magicSvgActions.SET_USER_TOKEN:
      return {
        ...state,
        userToken: action.userToken,
      };
    case magicSvgActions.SET_IMAGE:
      return {
        ...state,
        image: action.image,
      };
    case magicSvgActions.SET_RECENT_IMAGES:
      return {
        ...state,
        recentImages: action.recentImages,
      };
    case magicSvgActions.SET_GENERATION_STATUS:
      return {
        ...state,
        generationStatus: action.status,
      };
    case magicSvgActions.SET_SHOW_BANNER:
      return {
        ...state,
        showBanner: action.showBanner,
        userAction: action.userAction || '',
      };
    case magicSvgActions.SET_BUILDER_PAGE_ID:
      return {
        ...state,
        ...action.builderData,
      };
    case magicSvgActions.REGISTER_INSUFFICIENT_TOKEN_ATTEMPT:
      return {
        ...state,
        insufficientTokenAttempts: (state.insufficientTokenAttempts || 0) + 1,
      };
    default:
      return state;
  }
}
