import {css} from 'styled-components';

export default css`
input:not(.clearInput, .MuiInputBase-input) {
  &[type="text"],
  &[type="password"],
  &[type="number"],
  &[type="email"] {
    height: 2.5rem;
    padding: 0.5rem;
    border-radius: 4px;
    border: 1px solid #d4d7dc;

    &:hover {
      border: 1px solid #c8cbd0;
    }

    &:focus {
      border: 1px solid #00c0d4;
    }

    &::placeholder {
      color: #d4d7dc;
      opacity: 1;
    }

    &:disabled {
      background-color: #d4d7dc;
      border: 1px solid #d4d7dc;
    }
  }
}
`
